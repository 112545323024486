import React from 'react';

import WinXP from 'WinXP';
import { useGA } from 'hooks';

const App = () => {
  useGA('UA-5888545-16', 'winXP');
  return <WinXP />;
};

export default App;
